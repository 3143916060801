.main {
    > .wrapper {
        > .mailing-list {
            width: calc(100% - 2rem);
            background: $d-green url("/static/images/banner/mailing-list.png") no-repeat center;
            background-size: cover;
            padding: 1rem;
            display: flex;
            flex-direction: column;

            @include media-query(nano) {
                padding: 2rem 1rem;
            }

            @include media-query(micro) {
                padding: 2rem 3rem;
                width: calc(100% - 6rem);
            }

            @include media-query(giga) {
                padding: 4rem 1rem;
                width: calc(100% - 2rem);
            }

            @include media-query(max) {
                padding: 3.5rem 2rem;
                width: calc(100% - 4rem);
            }

            > .error {
                margin: 0 auto;
                background-color: rgba(0, 0, 0, 0.5);
                color: $coral;
                padding: 0.5rem;
            }

            > .heading {
                color: $d-green;
                background-color: $white;
                display: inline-block;
                padding: 0 0.5rem;
                margin: 1rem auto;

                @include media-query(milli) {
                    font-size: 3rem;
                    padding: 0.5rem 2rem;
                }
            }
            > form {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                @include media-query(milli) {
                    flex-direction: row;
                    justify-content: space-around;
                    width: 600px;
                    margin: 0 auto;
                    padding: 1rem 0 0 0;
                }

                @include media-query(kilo) {
                    width: 1000px;
                    padding: 1.5rem 0 0 0;
                }

                label {
                    color: $white;
                    max-width: 220px;
                    width: 100%;
                    text-align: center;
                    font-family: $body_font;

                    @include media-query(milli) {
                        order: 1;

                        &:last-child {
                            order: 2;
                        }
                    }

                    @include media-query(kilo) {
                        max-width: 424px;
                    }
                }
                input {
                    max-width: 220px;
                    width: 100%;
                    border: 0;
                    margin: 0.25rem 0 1rem 0;
                    padding: 0.5rem;

                    @include media-query(milli) {
                        order: 3;

                        &:last-child {
                            order: 4;
                        }
                    }

                    @include media-query(kilo) {
                        max-width: calc(424px - 2rem);
                        padding: 1rem;
                    }
                }
                > .subscribe {
                    @extend %button;
                    max-width: 110px;
                    flex-basis: auto;
                    order: 5;
                    padding: 1rem 0.5rem;
                    border: 0;
                    margin: 1rem auto;

                    @include media-query(milli) {
                    	margin: 1rem 2rem;
                    }

                    @include media-query(kilo) {
                        margin: 4rem 2rem 2rem 2rem;
                    }
                }
            }
        }
    }
}