.footer {
    > .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem;

        @include media-query(nano) {
            padding: 2rem 1rem;
        }

        @include media-query(micro) {
            padding: 2rem 3rem;
        }

        @include media-query(giga) {
            padding: 3rem 1rem;
        }

        @include media-query(max) {
            padding: 2rem 2rem 1rem 2rem;
        }
        > .f-nav {
            padding: 0 0 1rem 0;

            .wrapper {
                display: inline-block;
                margin: 0 0 0 0.75rem;
                .link {
                    text-decoration: none;
                    padding: .5rem;
    
                    &:hover {
                        background-color: #4c574c;
                        color: #FFF;
                    }
                }
            }
        }
        > .f-logos {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            max-width: 225px;
            padding: 0 0 1rem 0;
            font-size: 0;

            > .link {
                display: inline-block;
                width: 36px;
                height: 36px;
            }
            > .trip-advisor {
                background-image: url("/static/images/icons/trip-advisor.svg");
            }
            > .instagram {
                background-image: url("/static/images/icons/instagram.svg");
            }
            > .pinterest {
                background-image: url("/static/images/icons/pintrest.svg");
            }
            > .facebook {
                background-image: url("/static/images/icons/facebook.svg");
            }
            > .you-tube {
                background-image: url("/static/images/icons/youtube.svg");
            }
        }
        > .f-nav-misc {
            padding: 0 0 1rem 0;
            font-size: 0.9rem;

            @include media-query(milli) {
                padding: 0 0 3rem 0;
            }

            > .link {
                text-decoration: none;
                margin: 0 0 0 0.75rem;

                &:hover {
                    color: lighten($grey, 10%);
                }
            }
        }
        > .logo-bar {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem 0;

            @include media-query(milli) {
                flex-direction: row;
                justify-content: space-evenly;
                width: 100%;
                max-width: 1130px;
                padding: 2rem 0 3rem 0;
                align-items: flex-end;
            }

            > .link {
                padding: 0 1rem;

                @include media-query(milli) {
                    width: 15%;
                }

                @include media-query(kilo) {
                    width: 20%;
                }

                > .logo {
                    padding: 1rem 0;
                }
                > .v-york {
                    max-width: 100px;
                    @include media-query(kilo) {
                        max-width: 197px;
                    }
                }
                > .cla {
                    max-width: 100px;
                    @include media-query(kilo) {
                        max-width: 140px;
                    }
                }
                > .fsb {
                    max-width: 100px;
                    @include media-query(kilo) {
                        max-width: 125px;
                    }
                }
                > .green-award {
                    max-width: 50px;
                    @include media-query(kilo) {
                        max-width: 98px;
                    }
                }
            }
        }
    }
    > .f-contact {
        font-size: 0.9rem;
        width: 100%;
        border-top: 1px dashed $red;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1rem 0;
        text-align: center;

        > .text {
            margin: 0;
        }
    }

    .active {
        padding: .5rem;
        background-color: #4c574c;
        a {
            color: #FFF;
        }
    }

}