.main {
    > .wrapper {
        > .wrapper-inner {
            > .main-content {
                >.map {
                    width: 100%;
                    height: 300px;
                    padding: 1rem 0;
                }
                > .contact-form {
                    padding: 1rem 0;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 100%;

                    @include media-query(micro) {
                        width: 75%;
                    }
                    > .field {
                        padding: 0.5rem 0;
                        font-family: $body_font;
                        color: $grey;
                        display: grid;
                        grid-template-columns: 40% 60%;

                        > input {
                            padding: 0.5rem;
                            font-family: $body_font;
                        }
                        > textarea {
                            padding: 0.5rem;
                            font-family: $body_font;
                        }
                        > .g-recaptcha {
                            grid-column: 2;
                        }
                        > .button {
                            @extend %button;
                            border: none;
                            grid-column: 2;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}