%button {
    background-color: $yellow;
    padding: 1rem;
    text-decoration: none;
    font-family: $heading_font;
    font-size: 18px;
    color: $black;
    display: block;
    margin: 0.5rem auto;
    max-width: 150px;
    width: 100%;
    text-align: center;

    @include media-query(micro) {
        max-width: 185px;
        margin: 2.5rem auto;
        font-size: 1.667rem;
    }

    &:hover {
        background-color: lighten($yellow, 10%);
    }
}