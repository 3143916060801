.main {
    > .wrapper {
        > .wrapper-inner {
            > .sidebar {
                padding: 0 1rem 1rem 1rem;

                @include media-query(micro) {
                    width: calc(40% - 2rem);
                    float: left;
                    padding: 0 1rem 2rem 1rem;
                }

                @include media-query(milli) {
                    width: calc(30% - 2rem);
                }

                @include media-query(normal) {
                    padding: 0 1rem 2rem 2.5rem;
                    width: calc(30% - 3.5rem);
                }

                @include media-query(kilo) {
                    padding: 0 2.5rem 2rem 2.5rem;
                    width: calc(30% - 5rem);
                }

                @include media-query(giga) {
                    padding: 0 1.5rem 2rem 7rem;
                    width: calc(26% - 6rem);
                }

                > .heading {
                    font-size: 2.3rem;
                    font-weight: 500;
                    margin: 0.5rem 0 0 0;
                    text-transform: lowercase;

                    @include media-query(micro) {
                        margin: 0;
                        font-size: 3rem;
                    }
                }

                > .side-nav {
                    padding: 0;
                    margin: 0.9rem 0 1rem 0;

                    > .wrapper {
                        border-bottom: 1px dashed $coral;
                        list-style: none;
                        position: relative;
                        padding: 0.83rem 0;

                        &:first-child {
                            border-top: 1px dashed $coral;
                        }

                        &:hover {
                            background-color: $coral;
                            color: $black;
                            cursor: pointer;

                            > .link {
                                color: $black;
                            }
                        }

                        &:before {
                            display: none;
                        }

                        > .link {
                            text-decoration: none;
                            color: $grey;
                            display: block;
                            padding: 0 0 0 0.5rem;
                            text-transform: uppercase;

                            &:after {
                                background-image: url("../images/icons/chevron.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                content: '';
                                display: inline-block;
                                height: 25px;
                                width: 25px;
                                position: absolute;
                                right: 10px;
                            }
                        }
                    }

                    > .wrapper.active {
                        background-color: #e3686d;

                        .link {
                            color: #FFF;
                        }
                    }
                }
            }

            > .main-content {
                padding: 0 1rem 1rem 1rem;

                @include media-query(micro) {
                    padding: 0 1rem 2rem 1rem;
                    width: calc(60% - 2rem);
                    float: right;
                }

                @include media-query(milli) {
                    width: calc(70% - 2rem);
                }

                @include media-query(kilo) {
                    padding: 0 7.5rem 2rem 2.5rem;
                    width: calc(58% - 5rem);
                }

                .heading {
                    font-weight: 500;
                    text-align: left;
                    font-size: 2.3rem;
                    text-transform: lowercase;

                    @include media-query(micro) {
                        font-size: 2.8rem;
                    }
                }

                > .quote {
                    font-weight: 600;
                    color: $coral;

                }

                > .text {
                    font-size: 0.9rem;
                    //font-weight: 600;
                    line-height: 29px;

                    @include media-query(micro) {
                        font-size: 1rem;
                    }

                }

                > .image {
                    margin: 1rem auto;
                    display: block;
                }

                > .ul {
                    > .li {}
                }

                .coral-text {
                    color: $coral;
                }
            }
        }
    }
}
