.accordion-section {
    margin: 3em 0 1.5em 0;
    .accordion {
        .accordion-heading {
            background-color: $d-green;
            position: relative;
            font-size: 1.667rem;
            color: $lime;
            text-shadow: 0px 0px 2.88px rgba(0, 0, 0, 0.35);
            padding: 1em 2em 1em 1em;
            margin-bottom: 0.5rem;

            &:hover {
                cursor: pointer;
                background-color: lighten($d-green, 10%);
            }

            &:after {
                content: url("../images/icons/chevron.svg");
                display: inline-block;
                position: absolute;
                right: 2rem;
                width: 7px;
                height: 7px;
            }
        }
        .accoridion-content {
            display: none;
            margin: 0.5em 1em 1em 1em;
        }
    }
    .-active {
        .accordion-heading {
            background-color: $coral;
            color: $black;
            text-shadow: none;

            &:hover {
                cursor: pointer;
                background-color: lighten($coral, 10%);
            }

            &:after {
                content: url("../images/icons/chevron.svg");
                transform: rotate(90deg);
                right: 1rem;
            }
        }
        .accoridion-content {
            display: inline-block;
            font-size: 1.2rem;
            color: $grey;
            font-family: $body_font;
        }
    }
}
