//
// Typography
//

// Default styles for HTML5 block level elements
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

figure {
    margin: 0;
}

// Document body
body {
    color: $grey;
    line-height: 1.5;
    margin: 0;
    font-size: 18px;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

// Links
a {
    color: $black;
    font-family: $body-font;
}

a:focus {
    outline: 1px dotted;
}

// Basic typography
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading_font;
    font-size: 1em;
    line-height: 1.25;
    margin: 0;
    page-break-after: avoid;
}

h1 {
    font-size: 1.5em;

    @include media-query(milli) {
        font-size: 2.1em;
    }

    @include media-query(mega) {
        font-size: 3.2em;
    }
}

h2 {
    color: $red;
    font-weight: 500;
    font-size: 2.3rem;
    text-align: center;

    @include media-query(milli) {
        font-size: 2em;
    }
}

h3 {
    font-size: 1.25em;
}

hr {
    border: 0;
    border-top: 1px solid;
    margin: 1em 0;
}

blockquote {
    margin: 1rem 1.4rem;
    font-weight: 600;
    color: $coral;
    font-size: 1.3rem;
    font-family: $body_font;
    font-style: italic;

    @include media-query(normal) {
        font-size: 1.7rem;
        margin: 1rem 1.4rem;
    }
    
    span {
        font-style: normal;
    }
}

p {
    margin: 1rem 0;
    font-family: $body-font;
}

pre {
    margin: 1em 0;
    white-space: pre-wrap;
}

address,
cite,
dfn,
em,
i,
var {
    font-style: italic;
}

b,
strong {
    font-weight: bold;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 0.875em;
}

pre code,
pre kbd,
pre samp {
    font-size: 1em;
}

small {
    font-size: 0.75em;
}

sub,
sup {
    font-size: 0.75em;
    line-height: 0;
}

sub {
    vertical-align: sub;
}

sup {
    vertical-align: super;
}

// Lists
dl {
    margin: 1em 0;
}

dd {
    margin-left: 2em;
}

ol,
ul {
    margin: 1em 0;
    padding-left: 2em;
    font-family: $body_font;
    font-size: 1rem;
}

ol li {
    list-style: decimal outside none;
}

ol li li {
    list-style: lower-alpha outside none;
}

ol li li li {
    list-style: lower-roman outside none;
}

ul li {
    list-style: none;
    font-size: 1rem;

    &:before {
        content: "\2022";
        color: $coral;
        display: inline-block;
        width: 1rem;
    }
}

ul li li {
    list-style: circle outside none;
}

// Images
img {
    border: 0;
    height: auto;
    max-width: 100%;
}

// Tables
table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1em auto;
}

td,
th {
    padding: 0.25em 0.5em;
    text-align: left;
}

.text {
    a {
        color: $grey;
        font-weight: 600;

        &:hover {
            color: lighten($grey, 10%);
        }
    }
}

.fade {
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;

    &:hover {
        opacity: 0.8;
    }
}