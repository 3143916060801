.main {
    > .wrapper {
        > .wrapper-inner {
            > .tent-highlights {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
                grid-auto-rows: 250px;
                grid-gap: 1rem;
                padding: 0 1rem 1rem 1rem;


                @include media-query(kilo) {
                    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
                    grid-gap: 2rem;
                    padding: 0 2.5rem 2rem 2.5rem;
                }

                @include media-query(giga) {
                    padding: 0 2.5rem 2rem 2.5rem;
                    grid-gap: 3rem;
                }

                > .highlight {
                    background-color: $d-green;
                    max-width: 145px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: center;
                    padding: 1.5rem 1rem;
                    margin-bottom: 1rem;

                    > .image {
                        max-width: 55px;
                        display: block;
                        margin: 0 auto 0.5rem auto;

                        @include media-query(micro) {
                            margin: 0 auto 1rem auto;
                        }
                    }
                    > .text {
                        font-size: 0.9rem;
                        color: $white;
                        margin: 0.3rem 0;
                    }
                }
            }
            > .gen-text-section {
                padding: 0 1rem 1rem 1rem;
                display: flex;
                flex-direction: column;

                @include media-query(milli) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }

                @include media-query(kilo) {
                    padding: 0 2.5rem 2rem 2.5rem;
                }

                @include media-query(giga) {
                    padding: 0 7.5rem 5rem 7.5rem;
                }

                > .image-section {
                    @include media-query(milli) {
                        width: calc(50% - 2.1rem);
                    }
                }
                > .text-section {
                    @include media-query(milli) {
                        width: calc(50% - 2.1rem);
                        padding: 0 0 0 1rem;
                    }
                    > .heading {
                        color: $red;
                        font-weight: 500;
                        font-size: 2.3rem;

                        @include media-query(milli) {
                            font-size: 3.333rem;
                        }
                    }
                    > .text {
                        font-size: 0.9rem;
                        line-height: 29px;

                        @include media-query(normal) {
                            font-weight: 600;
                        }

                    }
                    > .button {
                        @extend %button;
                        max-width: 150px;
                        font-weight: 600;
                        margin: 0.5rem 0;

                        @include media-query(normal) {
                            margin: 3rem 0 1rem 0;
                        }
                    }
                }
                &:nth-child(even) {
                    > .image-section {
                        @include media-query(milli) {
                            padding: 0 0 0 1rem;
                            order: 2;
                        }
                    }
                    > .text-section {
                        @include media-query(milli) {
                            width: calc(50% - 2.1rem);
                            order: 1;
                            padding: 0;
                        }
                    }
                }
            }
            > .price-table {
                border-bottom: 1px dashed $red;
                margin: 0 1rem 2rem 1rem;
                overflow-x: scroll;
                //Make it margin so that the border fits to the content.

                @include media-query(kilo) {
                    margin: 0 2.5rem 2rem 2.5rem;
                    overflow-x: hidden;
                }

                @include media-query(giga) {
                    margin: 0 7.5rem 5rem 7.5rem;
                }
                > .table {
                    border: 1px dashed $red;
                    text-align: center;
                    color: $d-green;
                    margin: 1rem auto 3rem auto;
                    font-family: $body_font;
                    width: 95%;

                    tr {
                        border: 1px dashed $red;
                        text-align: center;
                        th {
                            border: 1px dashed $red;
                            text-align: center;
                        }
                        td {
                            border: 1px dashed $red;
                            text-align: center;

                            > img {
                                width: 20px;
                            }
                        }
                    }
                }
                > .button-coral {
                    @extend %button;
                    background-color: $coral;
                    color: $black;
                    max-width: 245px;
                    font-family: $body-font;
                    font-size: 1.3rem;
                    text-transform: uppercase;
                    margin: 3.5rem auto;

                    @include media-query(normal) {
                        margin: 1.5rem auto 5.5rem auto;
                    }

                    &:hover {
                        background-color: lighten($coral, 10%);
                    }
                }
            }
            > .other-accom {
                padding: 0 1rem 2rem 1rem;

                @include media-query(kilo) {
                    padding: 0 2.5rem 2rem 2.5rem;
                }

                @include media-query(giga) {
                    padding: 0 7.5rem 3rem 7.5rem;
                }
                > .heading {
                    display: block;
                    margin: 1rem auto;
                    color: $lime;
                    background-color: $d-green;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0.5rem 1.5rem;
                    font-weight: 500;
                    max-width: 320px;

                    @include media-query(normal) {
                        font-size: 2.6rem;
                        margin: 1rem auto 3rem auto;
                    }
                }
                > .tent-link {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 2rem 0 3rem 0;

                    @include media-query(micro) {
                        width: calc(50% - 1rem);
                        float: left;
                        margin: 2.6rem 0 4rem 0;
                    }

                    + .tent-link {
                        @include media-query(micro) {
                            float: right;
                        }
                    }
                    > .heading {
                        position: absolute;
                        color: $lime;
                        background-color: $d-green;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 0.5rem 1.5rem;
                        font-weight: 500;
                    }
                    > .image {

                    }
                }
                > .text {
                    text-align: center;
                }
                &:after {
                    content:'';
                    display: block;
                    clear: both;
                }
            }
        }
    }
}

// Wamp styles - to be removed
.xdebug-error.xe-warning {
    display: none;
}

.table-price-wrap {
    display: block;
    width: 100%;
    overflow-x: auto;
    @include media-query(tera) {
        overflow-x: unset;
    }
    .short-month {
        display: none;

        @include media-query(tera) {
            display: inline;
        }
    }
}

.table-price {
    border: 1px dashed $red;
    text-align: center;
    color: $d-green;
    //margin: 1rem auto 3rem auto;
    font-family: $body_font;
    font-size: .9rem;
    margin: 0;
    width: 100%;

    @include media-query(micro) {
        font-size: 1rem;
    }

    @include media-query(tera) {
        margin: 1rem auto 3rem auto;
    }

    tr {
        border: 1px dashed $red;
        text-align: center;
        th {
            border: 1px dashed $red;
            text-align: center;
            padding: .1rem;
            
            @include media-query(tera) {
                padding: .25em .5em;
            }
        }
        td {
            border: 1px dashed $red;
            text-align: center;
            padding: .1rem;

            @include media-query(tera) {
                padding: 0.25em 0.5em;
            }

            > img {
                width: 20px;
            }
        }
    }
}