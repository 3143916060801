.main {
    > .wrapper {
        > .wrapper-inner {
            max-width: 1500px;
            width: 100%;
            margin: 0 auto;

            > .breadcrumb {
                padding: 0.5rem 1rem 1rem 1rem;

                @include media-query(normal) {
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0.5rem 6.5rem 3rem 2.5rem;
                }

                @include media-query(giga) {
                    padding: 0.5rem 6.5rem 3rem 7.5rem;
                    grid-gap: 3rem;
                }

                > .link {
                    color: $coral;
                    font-size: 0.8rem;
                    &:after {
                        content: '>';
                        color: $black;
                        display: inline-block;
                        padding: 0 0.3rem 0 0.3rem;
                        font-size: 0.78rem;
                    }
                }
                > .current {
                    font-size: 0.8rem;
                    font-family: $body_font;
                }
            }
        }
    }
}