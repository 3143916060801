.header {
    background-color: $white;
    overflow: hidden;

    > .wrapper {
        box-sizing: border-box;
        padding: 3.5rem 0 1em 0;
        max-width: 1920px;
        margin: 0 auto;
        overflow: hidden;
        border-bottom: 7px $coral solid;

        @include media-query(micro) {
            padding: 2rem 0 calc(2rem - 7px) 0;
        }

        @include media-query(milli) {
            padding: 2rem 1rem calc(2rem - 7px) 1rem;
        }

        @include media-query(normal) {
            padding: 2rem 1rem calc(1rem - 7px) 1rem;
        }

        @include media-query(kilo) {
            padding: 2rem 1rem calc(2rem - 7px) 1rem;
        }

        @include media-query(mega) {
            padding: 2rem 1rem calc(1rem - 7px) 1rem;
        }

        @include media-query(giga) {
            padding: 1rem 1rem calc(1rem - 7px) 1.5rem;
        }

        > .logo {
            padding: 1rem;

            @include media-query(normal) {
                display: inline-block;
                padding: 0;
                float: left;
                width: 100%;
                max-width: 216px;
            }

            @include media-query(kilo) {
                max-width: 177px;
            }

            @include media-query(giga) {
                max-width: 216px;
            }

            &:hover {
                opacity: 0.8;
            }

            > .wrapper {
                width: 100%;

                > .image {
                    width: 100%;
                    max-width: 185px;
                    margin: 0 auto;
                    display: block;

                    @include media-query(normal) {
                        max-width: 216px;
                    }
                }
            }
        }

        > .email-us {
            width: 100%;
            padding: 0.5rem 0;
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: $grey;
            font-size: 1rem;

            &:before {
                background: url("../images/icons/lead-pencil.svg") no-repeat center;
                background-size: 15px;
                display: inline-block;
                content: '';
                width: 25px;
                height: 25px;
                border-radius: 9999px;
                border: 3px $coral dotted;
                margin-right: 0.5em;
            }

            @include media-query(pico) {
                font-size: 1.11rem;

                &:before {
                    background-size: 20px;
                    width: 45px;
                    height: 45px;
                }
            }

            @include media-query(normal) {
                width: 150px;
                float: right;
                text-transform: uppercase;
                font-size: 1.25rem;
            }

            &:hover {
                color: lighten($grey, 10%);
                opacity: 0.9;

                &:before {
                    border: 3px lighten($coral, 10%) dotted;
                }
            }
        }

        > .nav-toggle {
            position: absolute;
            background-color: $white;
            width: 100%;
            height: 50px;
            top: 0;
            -webkit-box-shadow: 0px 2px 5px 0px rgba(115, 115, 115, 0.2);
            -moz-box-shadow: 0px 2px 5px 0px rgba(115, 115, 115, 0.2);
            box-shadow: 0px 2px 5px 0px rgba(115, 115, 115, 0.2);
            z-index: 200;

            > .image {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
            }

            &:hover {
                cursor: pointer;
            }

            @include media-query(micro) {
                display: none;
            }
        }

        > .nav {
            width: 100%;
            position: absolute;
            top: 50px;
            right: 0;
            max-width: 250px;
            background-color: $white;
            z-index: 200;
            margin: 0;
            padding-left: 0;

            &:after {
                clear: both;
                display: block;
                content: '';
            }

            @include media-query(micro) {
                top: 7px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: 100%;
                text-align: center;
            }

            @include media-query(normal) {
                float: right;
                justify-content: flex-end;
                width: calc(95% - 185px);
                text-align: right;
                padding: 0 0.5rem 0 0;
            }

            @include media-query(kilo) {
                max-width: 81%;
                width: calc(100% - 185px);
            }

            @include media-query(mega) {
                max-width: 86%;
            }

            @include media-query(giga) {
                max-width: 83%;
                top: 50px;
            }

            > .wrapper {
                padding: 0.5rem 0;
                list-style: none;
                border-top: 1px $d-green solid;

                &:last-child {
                    border-bottom: 1px $d-green solid;
                }

                &:hover {
                    background-color: $d-green;

                    > .link {
                        color: $white;
                    }
                }

                @include media-query(micro) {
                    border: none;
                    display: inline-block;

                    &:last-child {
                        border: none;
                    }
                }

                @include media-query(kilo) {
                    margin: 0 0.5rem;
                }

                @include media-query(max) {
                    margin: 0 1.8rem;
                }

                &:before {
                    display: none;
                }

                > .link {
                    text-decoration: none;
                    margin: 0 0 0 0.75rem;

                    @include media-query(micro) {
                        margin: 0;
                        padding: 0.5rem;
                    }

                    @include media-query(milli) {}
                }
            }

            > .wrapper.active {
                background-color: #4c574c;

                > .link {
                    color: #FFF;
                }
            }

            > .sign-post {

                @include media-query(micro) {
                    background: url("../images/icons/signpost.png") center no-repeat;
                    width: 187px;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 0.7rem;
                    position: relative;

                    &:after {
                        width: 4px;
                        height: 90px;
                        background-color: $black;
                        content: '';
                        position: absolute;
                        left: calc(50% - 2px);
                        right: calc(50% - 2px);
                        top: -4px;
                        z-index: -1;
                    }

                    &:hover {
                        background-color: $white;
                    }
                }

                @include media-query(max) {
                    margin: 0 0 0 2.5rem;
                }

                @include media-query(micro) {
                    span {
                        margin: 16px;
                    }
                }
            }
        }

        > .nav-overlay {
            background-color: rgba(0, 0, 0, 0.75);
            position: fixed;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

            @include media-query(micro) {
                display: none;
            }
        }
    }

    > .stick-book {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: $coral;
        z-index: 999;

        @include media-query(normal) {
            top: 30%;
            background-color: transparent;
            width: auto;
            height: auto;
            bottom: auto;
            animation-play-state: paused;
        }

        &:hover {
            background-color: lighten($coral, 10%);

            @include media-query(normal) {
                background-color: transparent;
                animation-play-state: running;
            }
        }

        > img {
            display: none;

            @include media-query(normal) {
                display: block;
                width: 60%;
            }

            @include media-query(giga) {
                width: 100%;
            }
        }

        > .text {
            text-align: center;
            font-size: 1.2rem;

            @include media-query(normal) {
                display: none;
            }
        }
    }
}

.-closed {
    display: none;
}
