.main {
    > .wrapper {
        > .content {
            padding: 0 1rem 1rem 1rem;

            @include media-query(nano) {
                padding: 2rem 1rem;
            }

            @include media-query(giga) {
                padding: 3.5rem 1rem;
            }

            @include media-query(max) {
                padding: 3.5rem 2rem;
            }
            > .heading {
                color: $red;
                font-weight: 500;
                font-size: 2.3rem;
                text-align: center;

                @include media-query(milli) {
                    font-size: 3.333rem;
                }
            }
            > .text {
                font-size: 0.9rem;
                text-align: center;
                line-height: 29px;

                @include media-query(nano) {
                    width: 80%;
                    margin: 1rem auto;
                }

                @include media-query(milli) {
                    width: 65%;
                    font-size: 1rem;
                }

                @include media-query(normal) {
                    width: 55%;
                }

                @include media-query(tera) {
                    width: 50%;
                }
            }
            > .button {
                @extend %button;
            }
        }
    }
}