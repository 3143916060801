.main {
    width: 100%;
    > .wrapper {
        padding: 0 0 1em 0;
        max-width: 1920px;
        margin: 0 auto;
        position: relative;

        > .banner {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            > .hero {
                width: 100%;
                min-height: 10rem;
            }

            > .tag-line {
                color: $lime;
                background-color: $d-green;
                text-align: center;
                text-transform: uppercase;
                position: absolute;
                bottom: -120px;
                padding: 0 1rem;
                font-weight: 500;

                @include media-query(nano) {
                    bottom: 150px;
                    padding: 0 0.5rem;
                }

                @include media-query(micro) {
                    bottom: 220px;
                }

                @include media-query(milli) {
                    bottom: 270px;
                }

                @include media-query(normal) {
                    bottom: 420px;
                }

                @include media-query(mega) {
                    bottom: 500px;
                }

                @include media-query(tera) {
                    bottom: 550px;
                }

                @include media-query(max) {
                    bottom: 550px;
                }
            }
        }
        > .grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 1.5rem auto 0 auto;
            padding: 1rem;

            @include media-query(nano) {
                justify-content: center;
                margin-top: -3%;
                padding: 0;
                width: 100%;
                position: relative;
            }

            @include media-query(normal) {
                margin-top: -6%;
            }

            &:hover {
                cursor: pointer;
            }

            > .image {
                width: calc(50% - 0.25rem);
                align-self: center;
                padding: 0 0.25rem 0.25rem 0;

                + .image {
                    padding: 0rem 0rem 0.25rem 0.25rem;

                    + .image {
                        padding: 0.25rem 0.25rem 0 0;

                        + .image {
                            padding: 0.25rem 0 0 0.25rem;
                        }
                    }
                }

                @include media-query(nano) {
                    padding: 0 1rem;
                    width: calc(20% - 1rem);

                    + .image {
                        padding: 0 1rem;

                        + .image {
                            padding: 0 1rem;

                            + .image {
                                padding: 0 1rem;
                            }
                        }
                    }
                }

                @include media-query(normal) {
                    width: calc(16% - 1rem);
                }
            }
            > .hidden {
                display: none;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .tag-line {
        left: 50%;
        transform: translateX(-50%);
    }

    .cta-grid {
        > .link {
            position: relative;
            > .heading {
                left: 50%;
                top: 50%;
                transform: translate(-50%);
                position: absolute;
            }
        }
    }

    .mailing-list {
        position: relative;
        > .heading {
            max-width: 300px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .tent-highlights {
        display: flex !important;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        > .highlight {
            width: 100%;
            max-width: 17%;

            > .image {
                padding: 0 2rem;
            }
        }
    }
    .banner-inner {
        position: relative;
        > .tag-line {
            position: relative;
            left: 40%;
            transform: translateX(-50%);
        }
    }
}