.main {
    > .wrapper {
        > .cta-grid {
            display: flex;
            flex-direction: column;
            padding: 0 0 1rem 0;

            @include media-query(micro) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            > .link {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 0.5rem 0;
                min-height: 10rem;

                @include media-query(micro) {
                    width: calc(50% - 0.1rem);
                    padding: 0 0 0.2rem 0;
                }

                > .heading {
                    align-self: center;
                    justify-content: center;
                    position: absolute;
                    color: $lime;
                    background-color: $d-green;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0.5rem 1.5rem;
                    font-weight: 500;
                }

                > .note-text{
                    position: absolute;
                    color: white;
                    bottom: 0;
                    right: 15px;
                }
            }
        }
    }
}