.main {
    > .wrapper {
        > .wrapper-inner {
            > .gallery-section {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                padding: 0 1rem 2rem 1rem;

                @include media-query(micro) {
                    padding: 0 0 2rem 0;
                }

                > .slide {
                    width: 100%;
                    margin-bottom: 1rem;

                    @include media-query(micro) {
                        width: calc(50% - 2rem);
                    }

                    @include media-query(kilo) {
                        width: 30%;
                        margin-bottom: 2.9rem;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}