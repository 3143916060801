.main {
	> .wrapper {
		> .banner-inner {
            background: url("/static/images/banner/inner-banner.jpg") no-repeat center;
            background-size: cover;
            padding-top: 16rem;

            @include media-query(normal) {
                padding-top: 27.5rem;
            }

			> .tag-line {
				color: $black;
				background-color: $yellow;
				bottom: auto;
                top: 50%;
                transform: translateY(-50%);
                font-size: 2.5rem;
                letter-spacing: -3.5px;
                text-transform: lowercase;

                @include media-query(micro) {
                    font-size: 3rem;
                }

                @include media-query(normal) {
                    font-size: 4rem;
                }

                @include media-query(max) {
                    font-size: 5rem;
                }
			}

            > .info-box {
                font-size:1rem;
                text-transform:none;
                margin:70px 20px 0;
                letter-spacing:normal;

                @include media-query(milli) {
                    font-size:1.2rem;
                }
                @include media-query(normal) {
                    font-size:1.4rem;
                    margin-top:100px;
                }
                @include media-query(kilo) {
                    font-size:1.6rem;
                }
            }
		}
        > .cta-grid-inner {
            display: flex;
            flex-direction: column;
            padding: 0.2rem 0 1rem 0;

            @include media-query(nano) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            > .link {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 0.5rem 0;

                @include media-query(nano) {
                    width: calc(50% - 0.1rem);
                    padding: 0 0 0.2rem 0;
                }

                > .image {

                }
                > .heading {
                    align-self: center;
                    justify-content: center;
                    position: absolute;
                    color: $lime;
                    background-color: $d-green;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0.5rem 1rem;
                    font-weight: 500;
                }
            }
        }
	}
}