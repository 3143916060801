.main {
    > .wrapper {
        > .video-section {
            padding: 0 1rem 1rem 1rem;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            @include media-query(normal) {
                flex-direction: row;
                justify-content: space-between;
            }

            @include media-query(nano) {
                padding: 2rem 1rem;
            }

            @include media-query(micro) {
                padding: 2rem 3rem;
            }

            @include media-query(giga) {
                padding: 4rem 1rem;
            }

            @include media-query(max) {
                padding: 4.7rem 2rem;
            }

            > .left {
                padding: 0 0 1rem 0;

                @include media-query(normal) {
                    width: calc(50% - 1rem);
                    margin: 0 1rem 0 0;
                }

                > .cgit-wp-responsive-video {
                    position: relative;
                    height: 0;
                    overflow: hidden;
                    width: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                    padding-top: 15rem;

                    @include media-query(micro) {
                        padding-top: 20rem;
                    }

                    @include media-query(normal) {
                        max-width: 600px;
                        padding-top: 21rem;
                        margin: 0 0 0 auto;
                    }

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            > .right {
                display: flex;
                flex-direction: column;
                font-size: 1rem;

                @include media-query(normal) {
                    width: calc(50% - 1rem);
                    justify-content: center;
                    margin: 0 auto 0 1rem;
                }

                @include media-query(tera) {
                    width: 35%;
                    margin: 0 auto 0 1rem;
                }
                > .heading {

                    @include media-query(normal) {
                        text-align: left;
                    }

                }
                > .button {
                    @extend %button;

                    @include media-query(normal) {
                        margin: 2.5rem 0;
                        max-width: 140px;
                    }
                }
            }
        }
    }
}